$(document).ready(function(){
  // COMPONENTS
  $modal = $('#signupModal');

  $(".get-started-button").click(function() {
  	if (ga) {
  		ga('send', 'event', 'Click', 'Clicked Get Started')
	}
  });

});

